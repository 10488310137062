import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Share from "../components/Share"
import Sidebar from "../components/Sidebar"

const ArticlePostTemplate = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  let featuredImgFluid =
    post.frontmatter.featured_image.src.childImageSharp.fluid

  const createFullPostMarkup = () => {
    return { __html: `${post.frontmatter.lead || post.excerpt}` }
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.metatitle || post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="container-lg">
        <div className="row">
          <div className="col-lg-8">
            <article
              className="article"
              itemScope
              itemType="http://schema.org/Article"
            >
              <h1 itemProp="headline">{post.frontmatter.title}</h1>

              <div className="article-meta">
                <div>
                  {/* By <span itemProp="author">{post.frontmatter.author}</span> | */}
                  Published{" "}
                  <span itemProp="datePublished">{post.frontmatter.date}</span>
                </div>
              </div>

              <p
                className="article-lead"
                dangerouslySetInnerHTML={createFullPostMarkup()}
              ></p>

              <Share
                title={post.frontmatter.title}
                url={location.href}
                size={30}
              />

              <div className="article-toc">
                <h5>Contents</h5>

                <ul>
                  {post.tableOfContents.items.map((heading, index) => (
                    <li key={index}>
                      <a href={`${heading.url}`}>{heading.title}</a>

                      {heading.items ? (
                        <ul>
                          {heading.items.map((subheading, subindex) => (
                            <li key={subindex}>
                              <a href={`${subheading.url}`}>
                                - {subheading.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              <Img
                fluid={featuredImgFluid}
                alt={post.frontmatter.featured_image.alt}
                className="featured-image"
              />
              <span className="image-credit">
                {post.frontmatter.featured_image.credit}
              </span>

              <MDXRenderer>{post.body}</MDXRenderer>

              <div className="article-meta">
                <p>
                  {/* By <span itemProp="author">{post.frontmatter.author}</span> | */}
                  Published{" "}
                  <span itemProp="datePublished">{post.frontmatter.date}</span>
                </p>

                <Share
                  title={post.frontmatter.title}
                  url={location.href}
                  size={30}
                />
              </div>
            </article>
          </div>
          <div className="col-lg-4">
            <Sidebar current={post.id} related={true} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ArticlePostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        date(formatString: "D MMMM Y")
        metatitle
        description
        categories
        author
        lead
        featured_image {
          src {
            childImageSharp {
              fluid(maxWidth: 1110, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          credit
        }
      }
      excerpt
      body
      tableOfContents
      headings {
        value
      }
    }
  }
`
